.productordenado{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    padding: 20px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

@media screen and (max-width:1000px){
    .agregardenar{
        margin:  0;
        width: 100vw;
        height: 100vh;
    }
}
@media screen and (max-width:700px){
    .agregardenar{
        margin:  0;
        width: 100vw;
        height: 100vh;
    }
    .productordenado{
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        padding: 0;
        padding-top: 20px;
        margin-bottom: 10px;
    }
}