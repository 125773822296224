.posicionFooter{
    background-color: rgb(235, 235, 235);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.mostrarimagenfondo i {
    font-size: 50px;
    color: #fff;
    position: fixed;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.subirFotoGaleria{
    display: flex;
    flex-direction: row;
    padding: 20px;
    padding-top: 0;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-between;
    @media screen and (max-width:600px) {
        flex-direction: column;
    }
    button{
        border-radius: 10px;
        color: white;
        background: rgb(0, 100, 38);
        border: solid 1px rgb(0, 100, 38);
        cursor: pointer;
        padding: 10px;
        &:hover{
            color: rgb(0, 100, 38);
            background: white;
            border: solid 1px rgb(0, 100, 38);
        }
        @media screen and (max-width:600px) {
            margin: 0 auto;
            width: 50%;
            margin-top: 20px;
            padding: 10px;
            font-size: 14px;
        }
    }

    div{ 
    &:nth-child(2){
        margin-left: 20px;
        @media screen and (max-width:600px) {
            margin-left: 0px;
        }
    }
    display: flex;
    flex-direction: row;
    @media screen and (max-width:600px) {
        flex-direction: column;
    }
    select{
        margin-left: 10px;
        border-radius: 5px;
        padding: 5px;
        @media screen and (max-width:600px) {
            margin-left: 0px;
            margin-top: 8px;
            width: 90%;
        }
    }
    .file-select {
        position: relative;
        display: inline-block;
        margin-left: 10px;
        @media screen and (max-width:600px) {
            margin-left: 0px;
            margin-top: 8px;       
         }
      }
      
      .file-select::before {
        background-color: rgb(0, 100, 38);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        content: 'Seleccionar Foto'; 
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @media screen and (max-width:600px) {
           border-radius: 10px;
           width: 90%;
         }
      }
      
      .file-select input[type="file"] {
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: inline-block;
        
      }
    span{
        display: flex;
        letter-spacing: 1px;
        color: rgb(0, 100, 38);
        font-weight: 600;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width:600px) {
            font-size: 16px;
            margin-top: 8px;       
         }
    }
}

}

.mostrarimagenfondo{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    background-size: cover;
    background: linear-gradient(rgba(5,7,12,0.9),rgba(5,7,12,0.9));
    width: 100%;
    height: 100%;
    z-index: 20;
 img{
    max-height: 90vh;
    margin: auto;
    max-width: 1000px;
    z-index: 20;
}
}

.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 152px;
    padding: 20px;
    grid-gap: 10px;
    max-width: 1280px;
    margin: 0 auto;
    grid-auto-flow: dense;
    @media screen and (max-width:1100px) {
        grid-template-columns: repeat(3, minmax(200px, 1fr));

    }
    @media screen and (max-width:657px) {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
    }
    .gallery__item {
        position: relative;
        &:hover{
            opacity: 0.9;
        }
        .gallery__img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 20px;
           }
      
        
       }
       .cuadradoImagenes {
        grid-column: span 2;
        grid-row: span 3;
      }
      .anchoImagenes {
        grid-column: span 3;
        grid-row: span 2;
      }
       .tallImagenes {
        grid-row: span 3;
        @media screen and (max-width:600px) {
            grid-column: span 2;
        }
      }
    
   }

.gallery-image{
    height: 70vh;
    overflow-x: scroll;
    width:90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: auto;
    &::-webkit-scrollbar{
        height: 10px;
        background-color: white;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #3D9A67;
        border-radius: 20px;
    }
    .gallery-image-row{
        height: 50%;
    }
    .gallery__img{
        height: 90%;
        margin-right: 30px;
        &:hover{
            cursor: pointer;
            opacity: 0.7;
        }
    }
}
   
   
.header-page{
    background-color: gray;
    padding: 9% 5%;
    background: url("../componentes/images/banner-images.png");
    background-repeat: no-repeat;
    background-size: cover;
    .header-page-title{
        font-family: "Poppins";
        text-align: center;
        font-size: 48px;
        color: white;
        font-weight: 700;
        @media screen and (max-width:600px) {
            font-size: 30px;
            font-family: sans-serif;
          }
    }   
    .header-page-parrafo{
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        color: white;
        @media screen and (max-width:600px) {
            font-size: 13px;
            font-family: sans-serif;
          }
    }  
}