.pagineCard{
	margin: 0 auto;
	background: #EBEBEB;
	display: grid;
	grid-template-columns: 1fr repeat(5,1fr);
}
.fondolist{ 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	width: 100%;
	min-height: 100vh;
	background: #EBEBEB;
}
.contenidoinicio{
	background: white;
}
.card-body{
	margin-top: 10px;
}
.priceproductos{
	color: #333;
    display: flex;
    font-size: 24px;
    font-weight: 400;
    margin-right: 8px;
    line-height: 1.25;
	justify-content: space-between;
}
.disp{
	color: rgb(4, 116, 13);
	font-size: 0.8rem;
	font-weight: 600;
}
.Cardcompleta { 
	margin:2rem;
	grid-column: 2/8;
	font-family: sans-serif;
  }
  hr{
	  margin-bottom: 8px;
  }
  .flex {
	display: flex;
	flex-wrap: wrap;
    justify-content: space-evenly;
	
  }
  .centertextnoencontrado{
	  height: 100%;
	  justify-content: center;
  }
  .flex .h2noopcion{
	  font-size: 40px;
	  margin: auto;
  }
  
  .card {
	cursor: pointer;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	background: white;
    width: 30%;
	height: auto;
    margin-bottom: 10px;
	padding: 20px 50px 20px 0;
	transition: all 400ms ease;
	box-shadow: 0px 1px 10px rgb(0 0 0 / 20%);
	border-radius: 20px;
    min-width: 337px;

  }
  .card:hover{
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
	transform: translateY(-3%) ;
  }
  .card-img {
	margin: auto 10px;
	max-width: 150px;
	min-width: 150px;
    min-height: 150px;
	display: flex;
    flex-direction: column;
    justify-content: center;
	
  }
  .card-img img {
	  max-height: 150px;
	  max-width: 100%;
  }
  .left {
	text-align: left;
	padding-left: 1rem;
  }
  .bold {
	color: #333;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
  }

.margin-bottom{
	margin-bottom: 10px;
	font-size: 2rem;
	color: #2c5f43 ;
}

@media screen and (max-width:650px){
	.fondolist{
		display: block;
	}
	.flex .h2noopcion {
		font-size: 18px;
		margin: auto;
		margin-top: 50px;
	}
	.pagineCard{
		display: flex;
		flex-direction: column;
		min-height: 81vh;

	}
	.margin-bottom{
		font-size: 16px;
		background-color: #f5f5f5;
		font-weight: 600;
		padding: 15px;
		color: #333;
		margin-bottom: 0;
		text-align: center;
	}
	.Cardcompleta { 
		padding: 2rem;
        width: 100%;
		margin:0;
	    padding: 0;
		margin-bottom: 50px;
		margin-top: 20px;

	  }
	.bold {
		font-size: 1rem;
		font-weight: 100;
	  }
	.disp{
		color: rgb(4, 116, 13);
		font-size: 1rem;
	}
	.card{
		padding: 0;
		width: 100%;
		border-radius: 0;
		margin-bottom: 0;
		border: solid 1px #e2e2e2;
        box-shadow: none;
		min-width: auto;
	}

  }
  @media screen and (max-width:320px){

	.bold {
		font-size: 0.8rem;
		font-weight: 100;
	  }
	.priceproductos{
		font-size: 16px;
	}
	  .price{
		font-size: 1rem;
		font-weight: 500;
	}
	.disp{
		color: rgb(4, 116, 13);
		font-size: 0.5rem;
	}
	.card-img {
	 max-width: 90px;
	 min-width: 70px;
	 min-height: 100px;
	  }

	
  }
  
  