.contactanos-container-background{
    background-color: #004D40;
    margin-top: 100px;
    .contactanos-container{
        max-width: 1440px;
        margin: auto;
        @media screen and (max-width:950px) {
            padding: 30px 5% !important;
        }
        .contactanos-container-first{
            width: 50%;
            @media screen and (max-width:950px) {
                width: 100%;
                margin-right: 88px;
            }
            margin-right: 88px;
            @media screen and (max-width:950px) {
                width: 100%;
                margin-right: 0px;
            }
            .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard.MuiFormLabel-colorPrimary.Mui-focused.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-standard{
                font-size: 24px;
                font-family: "Poppins";
                font-weight: 700;
            }
            .contactanos-container-first-title{
                color: #3D9A67;
                font-family: "Poppins";
                font-weight: 700;
                font-size: 48px;
                @media screen and (max-width:950px) {
                    font-size: 40px;
                }
            }
            .contactanos-container-first-description{
                color: white;
                font-family: 'PoppinsRegular';
            }
         
            .contactanos-container-first-description-checkbox{
                color: white;
                margin: 0;
                margin-left: 10px;
            }
            .contactanos-container-first-button{
                min-width: 180px;
                padding: 10px 50.5px;
                background: #3D9A67;
                color: white;
                border: none;
                font-family: 'PoppinsRegular';
                cursor: pointer;
            }

            .contactanos-container-first-elements{
                color:#3D9A67
            }
        }
        .contactanos-container-second{
            width: 40%;
            display: flex;
            align-items: center;
            position: relative;
            @media screen and (max-width:950px) {
                display: none;
            }
            .contactanos-container-second-image{
                width: 110%;
                height: 130%;
                position: absolute;
            }
        }
    }
}
