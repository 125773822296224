.ContainerNosotros{
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .containerHijo{
    width: 100%;
    display: flex;
    flex-direction: row;
    .bolaIzquierda{
      height: 100%;
      background-color: white;
      position: absolute;
      top: 0%;
      left: 90%;
      border-top-right-radius: 50%;
      overflow: hidden;
      border-bottom-right-radius: 50%;
      }
    

      .bolaDerecha{
        height: 100%;
        position: absolute;
        background: #2c5f43;
        right: 85%;
        top: 0;
        border-top-left-radius: 50%;
        overflow: hidden;
        border-bottom-left-radius: 50%; 
      }
    &:nth-child(2){
      justify-content: end;
    }
    .HijoIzquierda{
      width: 65%;
      height: 100%;
      background: white;
      position: relative;
      padding-bottom: 20px;
      min-height: 33.33333vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .modificacionTextInformacion{
        width: 70%;
        margin-left: 10%;
      }

      p{
      color: rgb(0, 77, 64);
      font-weight: 400;
      font-size: 18px;
      width: 80%;
     }
     h2{
       font-size: 2rem;
       color: rgb(0, 77, 64);
       text-align: center;
       margin: 30px;
     }
    }
    .HijoDerecha{
      background: rgb(0, 77, 64);
      width: 65%;
      height: 100%;
      position: relative;
      padding-bottom: 20px;
      min-height: 33.33333333vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      .modificacionTextInformacion{
        width: 70%;
        margin-left: 25%;
      }
      h2{
        font-size: 2rem;
        color: white;
        text-align: center;
        margin: 30px;

      }
      p{
        color: white;
        font-weight: 400;
        font-size: 18px;
        width: 80%;
        margin-left: 16%;
       }
    }
  }
  .Verde{
    background: rgb(0, 77, 64);
  }
  .Blanco{
    background: white;
  }
}

@media screen and (max-width:1200px){
  .ContainerNosotros .containerHijo .HijoIzquierda p {
    font-size: 14px;
  
}
.ContainerNosotros .containerHijo .HijoDerecha p {
  font-size: 14px;



}
.ContainerNosotros .containerHijo .HijoIzquierda h2 {
    font-size: 1.5rem;
}
.ContainerNosotros .containerHijo .HijoDerecha h2 {
  font-size: 1.5rem;
}
  }

  @media screen and (max-width:540px){
    .ContainerNosotros {

      .containerHijo {
      .HijoIzquierda {
      width: 100%;
      display: flex;
      flex-direction: column;

      .modificacionTextInformacion{
        width: 80%;
        margin: auto;
      }

      p{
        margin: 0 10px;
      }
      h2{
        margin: 10px auto;

        }
      }
      .bolaIzquierda{
        position: initial;
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 0;
      }

      .HijoDerecha {
        width: 100%;
        display: flex;
        flex-direction: column;
        .modificacionTextInformacion{
          width: 80%;
          margin: auto;
        }
        p{
          margin: 0 10px;
        }
        h2{
          margin: 10px auto;
  
          }
        }
        .bolaDerecha{
          position: initial;
          width: 70%;
          margin: 0 auto;
          margin-top: 20px;
          border-radius: 0;
        }
}
}

    }