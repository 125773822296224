.quienes-somos-renew{
    padding: 40px 0;
    width: 90%;
    margin: auto;
    @media screen and (max-width:600px) {
        flex-direction: column !important;
      }
    .quienes-somos-renew-contenedor-image{
        width: 40%;
        @media screen and (max-width:600px) {
            width: 100%;
          }
        .quienes-somos-renew-contenedor-image-image{
            width: 100%;
        }
    }
    .quienes-somos-renew-contenedor{
        width: 60%;
        @media screen and (max-width:600px) {
            width: 100%;
            padding: 0 !important;
          }
        .quienes-somos-renew-contenedor-title{
            color: #3D9A67;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
        }
        .quienes-somos-renew-contenedor-parrafo{
            font-family: 'PoppinsRegular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #535353;
        }

        .quienes-somos-renew-contenedor-perfil{
            .quienes-somos-renew-contenedor-foto-perfil{
                border-radius: 50%;
                border: #3D9A67 solid 4px;
                overflow: hidden;
            }
            .quienes-somos-renew-contenedor-title-perfil{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                color: #3D9A67;
            }
            .quienes-somos-renew-contenedor-parrafo-perfil{
                font-family: 'PoppinsRegular';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #535353;
            }
        }
    }
}

.alert-perfil{
    padding: 38px 144px;
    width: 90%;
    margin: auto;
    background-color: #3D9A67;
    @media screen and (max-width:600px) {
        width: 100%;
        padding: 38px 18px;
        margin-bottom: 0px !important;
      }
    .alert-perfil-titulo{
        color: white;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
    }
    .alert-perfil-perfil{
        font-family: 'PoppinsRegular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #EBEBEB;
    }
}