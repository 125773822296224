.card-renew{   
    margin: auto;
    max-width: 330px;
    background: white;
    width: 90%;
    cursor: pointer;
    justify-content: space-between;
    .card-renew-container-image{
        width: 100%;
        justify-content: center;
        height: 240px;
        .card-renew-image{
            max-height: 100%;
            max-width: 100%;
        }
    }

    .card-renew-title{
        margin-top: 10px;
        font-family: "Poppins";
        color: #004D40;
        font-size: 24px;
        text-transform: capitalize;
    }
    .card-renew-descripcion{
        font-family: "PoppinsRegular";
        font-size: 16px;
        text-transform: capitalize;
    }
    .card-renew-subButton{
        border-radius: 50%;
        padding: 8px;
        &.green{
            background: #3D9A67;
        }
        &.green-dark{
            background-color: #004D40;
        }
    }
    .card-renew-price{
        color: #3D9A67;
        font-family: "Poppins";
        font-size: 24px;
        margin: 0;
    }
}
