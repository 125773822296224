.gridproducto {
  width: 90%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: white;
  padding: 20px 0px;
  margin: 7px auto;
  grid-gap: 50px;

  #fileperfilmas {
    display: none;
  }

}

.inicioPage{
  .tituloSubProducto {
    @media screen and (max-width:600px) {
        font-size: 1.5rem;
        text-align: center;
    }
    @media screen and (max-width:326px) {
        font-size: 1.1rem;
    }
    
}
}

.fleximg .fotoproducto {
  max-height: 550px;
  max-width: 90%;
  margin: auto;
}

.fleximg {
  .buttonagregarparrafo {
    box-sizing: content-box;
    margin: 0 auto;
    font-weight: bold;
    font-size: 15px;
    margin-top: 20px;
    border-radius: 20px;
    border: 1px solid grey;
    width: 70%;
    padding: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  .centerboton {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .botonguardarperfil {
      padding: 10px 20px;
      border-radius: 20px;
      font-size: 18px;
      background: rgb(26, 24, 24);
      color: whitesmoke;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-top: 10px;

      &:hover {
        cursor: pointer;
        background: whitesmoke;
        color: rgb(26, 24, 24);
        ;
      }
    }
  }

  .textareaparrafo {
    box-sizing: border-box;
    min-height: 200px;
    padding: 10px;
    font-size: 15px;
    max-width: 700px;
    width: 100% !important;
    max-height: 500px;
  }
}
.slider-producto{
  @media screen and (max-width:600px) {
    width: 90%;
    margin: auto;
  }
}
.product-description{
  width: 100%;
  padding: 80px 90px;
  background: #3D9A67;
  @media screen and (max-width:600px) {
    padding: 24px 13px;
  }
  .product-description-title{
    position: relative;
    color: white;
    margin-bottom: 40px;
    font-family: "Poppins";
    font-size: 32px;
    font-weight: 700;
    display: inline-block;
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: -20px;
      left: 0;
      background-color: white;
    }
  }

  .product-description-list{
    list-style: none;
    margin: 0;
    padding: 0;
    .infocom-title-list-item{
      margin-bottom: 20px;
      .flexrow{
        color: #EBEBEB;
        font-family: 'PoppinsRegular';

      }
    }
  }

  .product-description-subTitle{
    color: white;
    margin-bottom: 40px;
    font-family: "Poppins";
    font-size: 48px;
    font-weight: 700;
    @media screen and (max-width:600px) {
     font-size: 28px;
    }
  }
}
.morefotos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px auto;
  justify-content: space-between;

  i {
    font-size: 60px;
    margin: auto 5px;

    @media screen and (max-width:541px) {
      min-height: 33px;
      font-size: 36px;
      margin: 0;

    }

    cursor: pointer;
  }
}

.listmorefotos {
  width: 30%;
  margin: auto 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.cajafotoproducto {
  background-color: rgb(245, 244, 244);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}

.infocom {
  box-sizing: content-box;
  grid-column: 1/4;
  grid-row: 2/3;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .infocom-carrito{
    width: 300px;
    background: #004D40;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
  }
  .infocom-title{
    color: #3D9A67;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 32px;
    text-transform: capitalize;
  }

  .infocom-title-list{
    list-style: none;
    padding: 0;
    .infocom-title-list-item{
      font-family: 'PoppinsRegular';
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;    
        color: #535353;
    }
  }
  .infocom-price-first{
    color: #3D9A67;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
  }
  .infocom-price-second{
    color: #004D40;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-decoration: line-through #004D40;
  }
  .infocom-tags-list{
    font-family: 'PoppinsRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    word-spacing: 20px;
    .infocom-tags{
      color: #004D40;
      margin-right: 20px;
    }
  }
}

.infopro {
  margin-top: 20px;
  grid-column: 2/3;
  grid-row: 1/3;
  background: white;
  width: 90%;

  ul {
    li {
      .flexrow {
        justify-content: space-between;

        i {
          font-size: 25px;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.infopro h3 {
  font-family: sans-serif;
  font-size: 2.2rem;
  font-weight: 400
}

.infopro h4 {
  font-weight: 200;
  font-size: 1.5rem;
  margin-top: 1rem;
  font-family: sans-serif;

}

.infopro p {
  font-family: sans-serif;
  font-size: 0.8rem;
}

.flexcara {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexcara p {
  margin-right: 10%;
  margin-top: 20px;
}

.flexcara strong {
  font-weight: 700;
  font-size: 1rem;
  margin-right: 5px;
}

.infopro li {
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 1rem;
  margin-left: 17px;
}

.infocom h4 {
  font-family: sans-serif;
  font-size: 2rem;
}

.infocom p {
  font-family: sans-serif;
  margin-top: 10px;
  font-size: 1rem;
}

.infocom .disponiblecolor {
  margin-top: 30px;
  font-size: 1.4rem;
  color: green;
}

.infocom .cantidad {
  margin-top: 10px;
  padding: 5px;
  width: 60px;
  border-radius: 10px;
}

.infocom .botoncompra {
  cursor: pointer;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 1.02rem;
  border-radius: 15px;
  padding: 8px;
  border: none;

}

.infocom .amarillo {
  background: rgb(243, 220, 72);
}

.infocom .naranja {
  background: rgb(238, 136, 76);
}

.infocom .listamia {
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin-top: 40px;
  font-size: 1rem;
  border-radius: 5px;
  padding: 10px;
  border: solid black 1px;
  border-radius: 29px;

  a {
    text-decoration: none;
    color: black;
  }
}

@media screen and (max-width:1025px) {
  .gridproducto {

    padding: 20px 68px;
    margin: 0;
  }

  .morefotos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 0;
    margin-right: 0;
  }
}

@media screen and (max-width:900px) {
  .cajafotoproducto {
    height: 100%;
    text-align: center;
    min-height: auto;

  }

  .infopro {
    margin: 20px auto;
  }

  .gridproducto {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .infocom {
    margin: 0px auto 20px auto;
    width: 60%;
  }

  .fleximg .fotoproducto {
    height: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 80%;
    max-height: 350px;
  }
}

@media screen and (max-width:541px) {
  .infopro li {
    font-size: 0.8rem;
  }

  .flexcara p {
    font-size: 0.8rem;
  }

  .infopro h3 {
    font-size: 1.7rem;
  }

  .infopro h4 {
    font-size: 1.3rem;
  }

  .infocom {
    max-width: 100%;
    padding: 0;
    border: none;
    width: 90%;
  }
}