
.formUrl{
  margin-top: 60px;
  width: 80%;
  margin: 60px auto;
  display: flex;
  @media screen and (max-width:780px) {
  margin-bottom: 0; 
  
  }  
  input{
    width: 65%;
    padding: 8px 19px;
    border: solid 1px grey;
    border-radius: 21px;
  }
  button{
    background-color: #2c5f43;
    color: white;
    border: solid 1px green;
    border-radius: 10px;
    margin-left: 20px;  
    padding: 5px 10px;
    @media screen and (max-width:500px) {
      font-size: 0.7rem;
      }  
    &:hover{
      cursor: pointer;
      color: rgba(13, 180, 13, 1);
      background-color: white;
    }
  }
}

.pageVideos{
    margin: 0 auto;
    width: 100%;
    min-height: 70vh;
    width: 90%;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 50px;
    @media screen and (max-width:780px) {
        display: flex;
        flex-direction: column;
      }  
    .videoSeleccionado{
    width: 100%;
    span{
        letter-spacing: 1px;
        font-weight: bold;
        color: #094917;
    }
    iframe{
        width: 100%;
        margin-top: 20px;
    }
    }
    .VideosEspera{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        span{
        letter-spacing: 1px;
        font-weight: bold;
        color: #094917;
        @media screen and (max-width:780px) {
            margin: 0px auto;
          }  
        }
        div{
            @media screen and (max-width:780px) {
                
                display: flex;
                justify-content: space-between;
              }  
              div{
                 display: flex;
                 @media screen and (max-width:780px) {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                } 
                .botonvervideo{
                  cursor: pointer;
                  color: rgb(43, 43, 153);
                  font-weight: bold;
                  
              }
              
          .botonborrarvideo{
            cursor: pointer;
            color: red;
            font-weight: bold;
            margin-left: 10px; 
            @media screen and (max-width:780px) {
              margin-left: 0;
            } 
          }
              }
           
              
            
            iframe{
               width: 100%;
               margin-top: 20px;
               @media screen and (max-width:780px) {
                   width: 60%;
                 }  
                 
            }
        }
    }
    
   
    
}