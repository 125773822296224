.banner{
    background: linear-gradient(125.98deg, rgba(0, 77, 64, 0.3) -5.79%, rgba(61, 154, 103, 0.195) 71.03%), #FFFFFF;
    @media screen and (max-width:1200px) {
        padding: 30px 0 30px 5% !important;
    }
  }

.img-carrito-inicio{
    @media screen and (max-width:800px) {
        top: 70px;
    }
    @media screen and (max-width:700px) {
        top: 120px;
    }
}
@font-face {
    font-family: "PoppinsRegular";
    src: local("Poppins"),
    url("../styles/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "PoppinsBold";
    src: local("PoppinsBold"),
    url("../styles/Poppins-Black.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: local("PoppinsSemiBold"),
    url("../styles/Poppins-SemiBold.ttf") format("truetype");
    font-weight: normal;
}

.poppins{
    font-family: 'PoppinsRegular';
}

.title-home{
    font-family: "PoppinsBold";
    background: linear-gradient(180deg, rgba(235, 235, 235, 0.2) 0%, rgba(0, 77, 64, 0.2) 100%), #3D9A67;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 94px;
    @media screen and (max-width:1400px) {
        font-size: 60px;
    }
    @media screen and (max-width:1000px) {
        font-size: 30px;
    }

    @media screen and (max-width:500px) {
        text-align: center;
    }

}

.card-promotion{
    position: absolute;
    left: 20%;
    top:20%;
    background-color: white;
    border-radius: 20px;
    padding: 14px 41px 14px 20px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
    .card-promotion-p1{
        font-family: 'PoppinsBold';
        font-weight: 900;
        font-size: 32px;
        color: #3D9A67;
        margin: 0;
    }
    .card-promotion-p2{
        font-family: 'PoppinsBold';
        font-weight: 700;
        font-size: 26px;
        color: #004D40;
        margin: 0;
    }
    .icon{
        position: absolute;
        right: -40px;
        top: 10px;
    }
}

.buttons-home{
    border: 1px solid gray;
    font-size: 24px;
    padding: 10px 40px;
    color: #004D40;
    background-color: transparent;
    @media screen and (max-width:700px) {
        margin: 10px auto !important;
    }
    &.green{
        background-color: #004D40;
        color: white;
    }
    min-width: 250px;

}
.bannerDetailHome-title{
    text-align: center;
    font-family: "Poppins";
    margin: auto;
    font-weight: 700;
    color: white;
    &::after{
        position: absolute;
        content: "";
        width: 10%;
        height: 3px;
        background-color: #3D9A67;
        margin-top: 25px;
        margin-left: 30px;
    }
    &::before{
        position: absolute;
        content: "";
        width: 10%;
        height: 3px;
        background-color: #3D9A67;
        margin-top: 25px;
        margin-left: -222px;
    }
}
.bannerDetailHome{
    position: relative;
    width: 100%;
    background: linear-gradient(0deg, rgba(41, 133, 107, 0.7), rgba(28, 95, 76, 0.7)), url("../componentes/images/campo-home.png");
    background-blend-mode: normal, overlay;
    padding-top: 160px;

    @media screen and (max-width:700px) {
        padding-top: 80px;
    }

    padding-bottom: 278px;
    
    .bannerDetailHome-desc{
        text-align: center;
        margin: 8px auto;
        color: white;
        max-width: 700px;
    }
    .bannerDetailHomePart2{
        width: 80%;
        right: 0%;
        left: 0;
        position: absolute;
        top: -30px;
        margin: auto;
        margin-top: 334px;
        @media screen and (max-width:700px) {
            margin-top: 250px;
        }
        background: linear-gradient(0deg, rgba(0, 77, 64, 0.7), rgba(0, 77, 64, 0.7)), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../componentes/images/banner-fichas.png");
        background-blend-mode: normal, overlay;
        padding: 26px;
        .bannerDetailHomePart2-contenedor{
            border: 8px solid #3D9A67;
            .bannerDetailHome-title2{
                text-align: center;
                margin: auto;
                font-weight: 700;
                font-family: "Poppins";
                color: #3D9A67;
                margin-top: 75px;
                @media screen and (max-width:700px) {
                    margin-top: 30px;
                }
            }
            .bannerDetailHome-desc{
                text-align: center;
                margin: 8px auto;
                color: white;
                max-width: 700px;
                margin-bottom: 75px;
                @media screen and (max-width:700px) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.loMasVendido{
    position: relative;
    font-family: "PoppinsRegular" !important;
    color: #3D9A67;
    margin-bottom: 40px !important;
    margin-top: -150px !important;
    &::after{
        position: absolute;
        top: 50%;
        content: "";
        width: 200px;
        height: 3px;
        background-color: #3D9A67;
        margin-left: 20px;
    }
}
.background-ubicacion{
    background: url("../componentes/images/Vector.png");
    padding: 100px 0;
    .background-ubicacion-descripcion{
        text-align: center;
        max-width: 600px;
        width: 90%;
        margin: auto;
        font-family: "PoppinsRegular";

    }
}

.fondo-trituradora{
   background: linear-gradient(0deg, rgba(41, 133, 107, 0.7), rgba(28, 95, 76, 0.7)), url("../componentes/images/fondo-trituradora.png");
   background-blend-mode: normal, overlay;
   z-index: 4;
   top: 50%;
   width: 100vw;
}
.pointer{
    cursor: pointer;
}
.contendioinicio {
    background: rgb(235, 235, 235);
    height: 100%;
    overflow: hidden;
    padding-top: 92px;


    h1 {
        letter-spacing: 2px;
        color: #2b5844;
        text-align: center;
        font-weight: 700;
        font-style: normal;
        margin: 20px;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
    
    h4 {
        @media screen and (max-width:600px) {
            font-size: 1.5rem;
            text-align: center;
        }
        @media screen and (max-width:326px) {
            font-size: 1.1rem;
        }
        
    }

    .categoriasProductos {
        margin: 31px auto;
        max-width: 1280px;
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        grid-gap: 50px;
        padding: 10px;
        box-sizing: border-box;

        @media screen and (max-width:600px) {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 16px;
            padding: 0;
            margin: 31px 4px;

        }

        .categoriacajaproducto {
            position: relative;
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: white;
            padding: 20px;
            &::after{
                position: absolute;
                content: "";
                width: 90%;
                height: 90%;
                left: 5%;
                border: 3px solid #3D9A67;

            }

            @media screen and (max-width:600px) {
                margin: 0 10px;
            }
            @media screen and (max-width:300px) {
                margin: 0 25px;
            }
            .fondoverdeopacidad {
                width: 100%;
                height: 100%;
                background: #0c8141;
                top: 0;
                left: 0;
                position: absolute;
                opacity: 0.8;

            }

            img {
                position: relative;
                width: 100%;
                max-height: 280px;

            }
            
            
        }
    }
}

.tituloContactar {
    letter-spacing: 2px;
    color: #2b5844;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;

}
.slick-list{
    max-height: 400px !important;
}
.fondoVerdeContacto {
    width: 100%;
    height: 300px;
    background: #004d40;
    margin: 220px 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width:600px) {
        margin: 0;
        display: block;
        margin-bottom: 468px;
        padding-top: 20px;

    }

    .ContentInfor {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        width: 100%;
        max-width: 1280px;

        @media screen and (max-width:600px) {
            align-items: unset;
            display: block;
            padding: 0 ;
        }

        .informacionContacto {
            display: flex;
            flex-direction: column;
            font-size: 1.5rem;
            color: white;
            @media screen and (max-width:600px) {
                align-items: center;
            }
            span {
                letter-spacing: 1px;

                i {
                    margin-right: 10px;
                }
            }

            p {
                margin-bottom: 20px;
                font-size: 1rem;
                margin-left: 30px;
            }
        }

        form {
            width: 40%;
            height: 500px;
            background: white;
            max-width: 700px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            border-radius: 20px;

            @media screen and (max-width:600px) {
                width: 77%;
                margin: auto;
            }

            label {
                letter-spacing: 2px;
                color: #004d40;
                font-weight: 700;
                font-size: 1rem;
                margin: 10px 0;
            }

            input,
            textarea {
                padding: 5px;
                max-width: 100%;
                border: none;
                border-bottom: 2px solid #004d40;
            }

            textarea {
                height: 100%;
                max-height: 100%;
            }

            button {
                width: 50%;
                margin: 0 auto;
                padding: 10px;
                margin-top: 20px;
                border-radius: 10px;
                border: 1px solid #004d40;
                background: #004d40;
                color: white;
                cursor: pointer;

                &:hover {
                    color: #004d40;
                    background: white;
                }
            }
        }

    }

}

.sliderbargrande {
    margin: 0 auto;
    width: 80%;
    margin-bottom: 30px;

}

.mapaGps {
    width: 100%;
    margin-top: 20px;
    display: flex;
    margin-bottom: 100px;
    iframe {
        margin: 0 auto;
        width: 80%;
    }
}

.sliderbargrande div img {
    cursor: pointer;
    width: 100%;
    height: 300px;
}

.margininiciotop {
    margin: 200px auto 350px auto;
    position: relative;
    padding: 30px;
    background: linear-gradient(0deg, rgba(41, 133, 107, 0.7), rgba(28, 95, 76, 0.7)), url("../componentes/images/fondo-trituradora.png");
    background-blend-mode: normal, overlay;
    z-index: 4;
    width: 100%;
    @media screen and (max-width:700px) {
        padding: 10px;
    }
    .margininiciotop-arriba{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 1440px;
        position: relative;
        margin: auto;

        .margininiciotop-arriba-containers{
            display: flex;
            flex-direction: row;
            margin-top: 100px;
            padding: 32px;
            width: 48%;
            @media screen and (max-width:700px) {
                width: 100%;
            }
            @media screen and (max-width:500px) {
                width: 100%;
                flex-direction: column-reverse;
                justify-content: center;
            }
            background: #FFFFFF;
            .margininiciotop-arriba-containers-childrens{
                width: 65%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @media screen and (max-width:500px) {
                    width: 100%;
                }
                .margininiciotop-arriba-containers-childrens-title{
                    font-size: 29px;
                    font-family: "Poppins";
                    font-weight: 700;
                    color: #004D40;

                }
                .margininiciotop-arriba-containers-childrens-button{
                    background: #3D9A67;
                    color: white;
                    border: none;
                    width: 162px;
                }

                
            }
            .margininiciotop-arriba-containers-imgContainer{
                width: 35%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media screen and (max-width:500px) {
                    width: 100%;
                }
                .margininiciotop-arriba-containers-imgContainer-img{
                    width: 100%;
                }
            }
        }
    }

    .margininiciotop-arriba-container-three{
        width: 90%;
        margin: auto;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #EBEBEB;
        box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.25);
        padding-right: 20px;
        margin-top: 100px;
        margin-bottom: -200px;
        @media screen and (max-width:900px) {
            flex-direction: column;
        }
        .margininiciotop-arriba-container-three-imgContainer{
            width: 40%;
            background: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width:900px) {
                width: 100%;
                margin-bottom: 30px;
            }
            .margininiciotop-arriba-container-three-imgContainer-img{
                width: 100%;
            }
        }
        .margininiciotop-arriba-container-three-imgsContainers{
            width: 20%;
            @media screen and (max-width:900px) {
                flex-direction: row !important;
            }
            @media screen and (max-width:900px) {
                width: 100%;
            }
            align-items: center;
            .margininiciotop-arriba-container-three-imgsContainers-img{
                width: 55%;
                @media screen and (max-width:900px) {
                    width: 25%;
                }
            }
        }
        .margininiciotop-arriba-container-three-Container{
            width: 40%;
            @media screen and (max-width:900px) {
                width: 100%;
            }
            .margininiciotop-arriba-container-three-Container-title{
                margin-top: 1.5rem;
                color: #3D9A67;
                font-family: "Poppins";
                font-weight: 700;
                text-transform: capitalize;
                letter-spacing: 1px;
                font-size: 40px;
                @media screen and (max-width:500px) {
                    font-size: 29px;
                }
            }
            .margininiciotop-arriba-container-three-Container-parrafo{
                color: #535353;
                font-size: 24px;
                font-family: 'PoppinsRegular';
                text-transform: capitalize;

            }
            .margininiciotop-arriba-container-three-Container-price{
                color: #3D9A67;
                font-size: 40px;
                font-family: "Poppins";
                font-weight: 700;
                font-weight: 500;
                @media screen and (max-width:500px) {
                    font-size: 29px;
                }
            }
            .margininiciotop-arriba-container-three-Container-check{
                align-items: center;
                .margininiciotop-arriba-container-three-Container-check-text{
                    color:black;
                    margin:0;
                    margin-right: 10px;
                }
            }
            .margininiciotop-arriba-container-three-Container-button{
                padding: 10px 40.5px;
                margin-bottom: 20px;
                min-width: 206px;
                background: #3D9A67;
                color: white;
                border: none;
                font-family: 'PoppinsRegular';
                cursor: pointer;
            }
            .margininiciotop-arriba-container-three-Container-subButton{
                background: #D9D9D9;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}

.solid-divider{
    border-top: 3px solid #bbb;
}




.marginslider h2 {
    font-weight: 600;
    color: #2b5844;
    font-family: sans-serif;
    margin-bottom: 20px;
    letter-spacing: 1px;

    @media screen and (max-width:600px) {
        font-size: 14px;
        margin-left: 29px;
    }
}

.marginslider h2 a {
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: #04501b !important;

    @media screen and (max-width:600px) {
        font-size: 11px;
    }
}

.marginslider {
    margin: 100px auto;
    width: 1280px;
    margin: 100px auto;
}

.flexinicio {
    display: flex;
}



.imginicio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 224px;
    height: 224px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 10px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.carditem .descuentoinicio {
    margin: 6px 10px;
    font-size: 16px;
}

.carditem .precioinicio {

    margin-left: 10px;
    font-weight: 500;
    font-size: 1.5rem;
}

.carditem .enviogratisinicio {
    margin-left: 10px;
    font-weight: 600;
    font-size: 0.9rem;
    color: rgb(4, 144, 4);
}


.slick-prev:before,
.slick-next:before {
    color: black;
}



@media screen and (max-width:1348px) {

    .marginslider {
        margin: 100px auto;
        width: 1000px;
    }

}

@media screen and (max-width:1057px) {
    .sliderbargrande {
        width: 100%;
    }

        .marginslider {
        margin: 100px auto;
        width: 700px;
    }
}



@media screen and (max-width:885px) {

    

    .marginslider {
        margin: 100px auto;
        min-width: 50%;
    }
}

@media screen and (max-width:740px) {

    

    .marginslider {
        margin: 100px auto;
        width: 500px;
    }

}

@media screen and (max-width:667px) {

    .sliderbargrande div img {
        height: 150px;
    }
   
    .contendioinicio {
        .categoriasProductos{
            .categoriacajaproducto{
            p {
                    font-size: 1.4rem; 
            }
        }
        }
    }
}



@media screen and (max-width:500px) {

    

    .marginslider {
        margin: 100px auto;
        width: 300px;
    }

    .carditem {
        margin-left: 15%;
    }

    .carditemarca {
        margin: 0 auto;
    }

}

@media screen and (max-width:300px) {
    .carditem {
        margin: 0 auto;
    }
    


}