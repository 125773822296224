.filtercelular{
  display: flex;
  width: 100%;
  height: 40px;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin:0 auto ;    border: 1px solid #cfcfcf;}
.flexbotonfil{
  display: flex;
  flex-direction: row;
  width: 49%;
  justify-content: center;
  cursor: pointer;
}
.iconfilter{
 margin: auto 0;
  margin-right: 5px;
  
}
.filtercelular .flexbotonfil p{
  margin: auto 0;
}

.botonbusquedafilter{
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  font-size: 16px;
  font-weight:600;
  cursor: pointer;
  max-width: 200px;
  background-color: white;
  border: 1px solid #004D40;
  color: #535353;
 
}
.formfilter{
  background-color: white;

  font-family:  sans-serif;
  text-align: left;
  margin: 0 auto ;
  margin-top:  2rem;
  display: flex;
  flex-direction: column;
  padding: 0px 12px 12px 12px;

}
.divfilterflex{
  display: flex;
  flex-direction: column;
  margin-left: 20px;

}
.divfilterflex hr{
 margin-top: 40px;
 height: 70vh;
  
}
.inputf{
  flex: 1;
  padding: 4px;
  max-width: 121px;
  margin: 0 5px;
}
.colorf{
  color: rgb(51, 51, 51);
}

.flexche{
  display: flex;
  align-items: center;
}
.flexcher{
  display: flex;
}
.checkbox{
  margin: auto 10px;
}

.filtercelular hr{
  margin: auto 0;
  height: 40%;
}
hr{
  margin: 0;
}
.tamaño{
color:#004D40;
font-family: "Poppins";
font-size: 32px;
margin-bottom: 40px;
}
.hdeproduc{
  margin: 0;
margin-right: 26px;
font-size: 16px;
color: #535353;
font-family: "Poppins";
font-weight: 400;
}
.select{
margin: 5px;
margin-left: 10px;
padding: 7px;
flex:1;
}
@media screen and (max-width:540px){
  .divfilterflex hr{
    height: 1px;
     
   }
	.formfilter{
  flex-direction: row;
    height: 100%;
   font-family:  sans-serif;
   text-align: left;
   margin: 0 auto ;
   margin-top:  0;
  flex-wrap: wrap;
  }
  .colorf{
    margin:  0 20px;
      }
  .tamaño{
    margin-top: 20px;
    font-size: 20px;
    width: 100%;
    }
    .flexche{
      margin-top:10px ;
      
    }
    
    .botonbusquedafilter{
      margin: 10px auto;
    }
    .select{
      margin: 0;
    }
    .ta-center{
      margin: auto 20px;
      color: rgb(51, 51, 51);
    }
  }
  
@media screen and (max-width:321px){
  .checkbox{
    margin: 3px 9px;
  }
  }
  
  