.footer{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #004D40;;
    padding:30px 0px;
    font-family: 'Play', sans-serif;
    text-align:center;
    }
    
    .footer .rowsIcon{
    width:100%;
    margin:1% 0%;
    padding:0.6% 0%;
    color:white;
    font-size:0.8em;
    }
    
    .footer .rowsIcon a{
    text-decoration:none;
    color:white;
    transition:0.5s;
    .bx{
        color: #3D9A67;
        font-size: 40px;
    }
    }
    
    .footer .rowsIcon a:hover{
    color:#fff;
    }
    
    .footer .rowsIcon ul{
    width:100%;
    @media screen and (max-width:600px) {
        padding: 0;
      }
    }
    
    .footer .rowsIcon ul li{
    display:inline-block;
    margin:0px 30px;
    }
    
    .footer .rowsIcon a i{
    font-size:2em;
    margin:0% 1%;
    }
    
    @media (max-width:720px){
    .footer{
    text-align:left;
    padding:5%;
    }
    .footer .rowsIcon ul li{
    display:block;
    margin:10px 0px;
    text-align:left;
    }
    .footer .rowsIcon a i{
    margin:0% 3%;
    }
    }

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic{
    box-shadow: none;
  }