.cajaCategoriasmostrar{
  position: relative;
  max-width: 1430px;
  width: 90%;
  min-height: 100vh;
  margin: 0px auto;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 50px;
  padding: 10px;
  box-sizing: border-box;
  @media screen and (max-width:540px) {
    width: 100%;
    grid-template-columns:1fr;
    padding: 0px;
    margin-top: 0px;
  }  
  .ProductosCategorias{
    .titlecategoria{
      text-align: center;
      letter-spacing: 1px;
      background-color: rgb(0, 77, 64);
      height: 40px;
      display: flex;
      justify-content: center;   
      flex-direction: column;
      border-radius: 10px;
      color: white;
      @media screen and (max-width:540px) {
      border-radius: 0px;
      font-size: 15px;
      }  
    }
   
    h3{
      margin-top: 20px;
      font-size: 20px;
      @media screen and (max-width:540px) {
        font-size: 15px;
        margin: 10px;
        }  
    }
    .contenedoresproductoscategoria{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));;
    justify-content: center;
    grid-gap: 50px;
    
    @media screen and (max-width:540px) {
      border-radius: 0px;
      grid-template-columns: 1fr; 
     justify-content: start; 
     grid-gap: 0px;
     margin-top: 0;
      } 

    div .carditem{
      margin: 10px auto;
    }
    }
  }


}


.TodasCategorias{
  h3{
    text-align: center;
    letter-spacing: 1px;
    background-color: rgb(0, 77, 64);;
    height: 40px;
    display: flex;
    justify-content: center;   
    flex-direction: column;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    @media screen and (max-width:1000px) {
      font-size: 15px;
      } 
    @media screen and (max-width:540px) {
     border-radius: 0px;
      }   
  }

 aside {
    padding: 0 15px;
    .titulo{
      margin-bottom: 10px;
    }
    .contenedor-temario{
      padding-top: 20px;
    }
    .lista{
      list-style: none;
      border-left: 2px solid #c9c9c9;
      padding-left: 10px;
      margin-left: 10px;
      li.activo::before {
        background: #ffbd86;
        border: 2px solid #ff7300;

         }  
      li.activo a{
        color: #000;
         }
      
         li.categoriaSeccionactivo{
           &::before{
           background: #8aff86;
           border: 2px solid rgb(0, 77, 64);;
           }
         }
      li.categoriaSeccion {
    
        margin-left: 25px;
        &::before{
          transform: rotate(0deg);
        }
      }
      li.categoriaSeccion2 {
        margin-left: 50px;
      }
      li.categoriaSeccion2activo{
        &::before{
          background: #ffbd86;
        border: 2px solid #ff7300;
          }
      }
      li{
        position: relative;
        a{
          display: block;
          color: #5a5a5a;
          padding: 15px 10px; 
          text-decoration: none;
          transition: .3s ease all;
          &:hover{
            color: #000;
          }
        }
        &::before{
          content: "";
          display: block;
          height: 12px;
          width: 12px;
          background: #f2f2f2;
          border: 2px solid #c9c9c9;
          position: absolute;
          left: -19px;
          top: calc(50% - 6px);
          transform: rotate(45deg);
        }
      }
    }
}

}

.page-categorias{
  padding: 5%;
  background-color: rgb(251,251,251);
  @media screen and (max-width:630px) {
    padding: 0;
}
  .page-categorias-filter{
    width: 20%;
    min-width: 200px;
    @media screen and (max-width:600px) {
      width: 90%;
      margin: auto;
      margin-bottom: 33px;
    }
  }
  .page-categorias-products{
    padding-top: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width:600px) {
      margin: auto;
      width: 100%;
    }
  }
  .page-categorias-filter-container{
    @media screen and (max-width:600px) {
      flex-direction: column-reverse !important;
    }
  }
  .page-categorias-first{
    border: 1px solid black;
    align-items: center;
    @media screen and (max-width:600px) {
      flex-direction: column !important;
    }
    .page-categorias-first-title{
      width: 20%;
      min-width: 200px;
      font-size: 32px;
      font-family: "Poppins";
      font-weight: 700;
      color: #004D40;
      padding: 20px 32px;
    }
  }
  
    .page-categorias-second-des{
      width: 80%;
      background-color: #3D9A67;
      padding: 21px 0;
      font-family: "PoppinsRegular";
      color: white;
      
      font-size: 16px;
      margin: 0;
      box-sizing: content-box;
      @media screen and (max-width:600px) {
      width: 100%;
      padding: 21px 0 !important;
    }
  }
}