.form-agregar-producto{
    min-height: 100vh;
    width: 100%;
    background: rgb(235, 235, 235);
    margin: 0 auto;
    position: absolute;
    #fileproducto{
        display: none;
    }
    .fileproductoeditar{
        display: none;
    }
    .square-form{
        position: relative;
        margin: 30px auto 50px auto;
        max-width: 1280px;
        background-color: white;
        min-height: 247px;

        .bxs-folder-plus,.bxs-message-square-x{
            position: absolute;
            right: 10px;
            font-size: 40px;
            top: 5px;
            &:hover{
                cursor: pointer;
            }
        }
        .opcioncategoria{
            position: absolute;
            font-size: 25px;
            top:4px;
            right:104px;
            color: white;
            width: 40px;
            height: 40px; 
            background: black;
            text-align: center;
            vertical-align: center;
            border-radius: 50%;
            padding-top: 5px;
            &:hover{
                cursor: pointer;
            }
        }
        .activocategoria{
            color:black;
            background: rgb(139, 139, 139);
        }
        
        .opcioncategoria:nth-child(2){
            right:155px;
            top: 4px;
        }
        .opcioncategoria:nth-child(4){
            right:55px;
        }
      
        table {
            min-width: 1000px;
            background-color: #004D40;
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            caption{
            font-size: 25px;
            font-weight: bold;
            margin: 20px auto;
            letter-spacing: 1px;
            color: #2c5f43;
           
        }
            tbody{
                background-color: #3D9A67;
                tr{
                    border-top: 25px solid #3D9A67;
                    border-left: 25px solid #3D9A67;
                    border-right: 25px solid #3D9A67;
                    background: white;
                }
            }
            thead{
                tr{
                    background-color: #004D40;
                    color: white;
                }
            }
            td {
                box-sizing: border-box;
                height: 200px;
                .textproducto{
                    margin: 0;
                    min-width: 99%;
                    min-height: 200px;
                    max-width: 100%;
                }
                .botonproductoagregar{
                    color: rgba(14, 1, 1, 0.692);
                    padding: 5px;
                    font-weight: bold;
                    border-radius: 10px;
                    border: 1px solid gray;
                    margin: 10px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .delete{
                    background-color: rgb(255, 159, 159);
                }
                .add{
                    background-color: rgb(159, 255, 188);
                }
                &.title{
                    color:#004D40;
                    font-weight: bold;
                    font-size: 24px;
                    font-family: "PoppinsRegular";
                }
                ul{
                    text-align: start;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                li{
                    color: #004D40;
                    span{
                        font-size: 15px;
                        font-weight: bold;
                        margin-right: 10px;
                       
                    }
                    b{
                        font-size: 15px;

                    }

                    select{
                     font-size: 10px;
                     border-radius: 3px;
                    }
                }

            }
        }
        th{
            .hover{
                &:hover{
                    opacity: 0.5;
                    cursor: pointer;
                }
            }
        }
          }
          
          thead th:nth-child(1) {
            width: 18%;
          }
          
          thead th:nth-child(2) {
            width: 20%;
          }
          
          thead th:nth-child(3) {
            width: 20%;
          }
          
          thead th:nth-child(4) {
            width: 20%;
          }
          thead th:nth-child(5) {
            width: 10%;
          }
          
          th, td {
            text-align: center;
            padding: 20px;
          }
          td img{
            max-height: 200px;
             max-width: 100%;
          }
    }

}