.fondocarrito{ 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%; 
    height: 100%;
    min-height: 100vh;
    background:rgb(235, 235, 235);  
    padding-top: 70px;
}
.carrito-Price{
    font-family: "Poppins";
    font-size: 20px;
}
.flexro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.comprarbotoncarrito{
    border: none;
   font-size: 20px;
   margin: 0 auto;
   margin-top: 20px;
   cursor: pointer;
   align-items: center;
   display: flex;
   flex-direction: column;

   a{
    display: flex;
    margin: auto;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    color: white;
    background-color: #3D9A67;
    letter-spacing: 0px;
    text-align: center;
    padding: 10px 25px
   }
}
.conteinerproductoseleccionado{
box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
background: white;
width: 70%;
margin: 0px auto ;
margin-bottom: 40px;
padding: 46px 32px;
padding-bottom: 30px;
position: relative;
h2{
    
    @media screen and (max-width:550px){
        margin-top: 10px;
    margin-bottom: 10px;
        font-size: 15px;
}
@media screen and (max-width:300px){
        font-size: 10px;
}
}
}
.flexrow{
    display: flex;
    flex-direction: row;
 
}
.preciocarrito{
     
    font-size: 25px;
    
}
.infoaprobado{
    font-size: 18px;
    color: #877676;
    margin-top: 5px;

}
.comprarbotoncarrito-parrafo{
    color: #004D40;
    font-family: "Poppins";
    letter-spacing: 0px;
}
.flexrowcarrito{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.productocarrito{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    margin-top: 44px;
    margin-bottom: 48px;
}
.productoventa{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    margin: 10px 0;
    padding: 10px;
    box-sizing: border-box;
    background: #def3e2;
    border-radius: 10px;
}
.botoncarrito{
    cursor: pointer;
    color: #000;
    text-decoration: none;
    margin-right: 5rem;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 15px;
    &:hover{
        opacity: 0.7;
    }
    
}
.opacityOprimer{
    color: #535353;
}
.hr-carrito{
    border: 1px solid black;
}
.img-producto{
    cursor: pointer;
    max-height: 150px;
    width: 20%;
    text-align: center;
    margin-right: 20px;
}
.img-producto img{
   max-width: 90%;
   max-height: 100%;
}
.caracteristicaproductocarrito{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}
.flexcolum{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.mostrartextotitulocarrito{
    cursor: pointer;
    font-size: 18px;
    text-decoration: none;
    color: black;
    font-weight: 600;
}
.cbasica{
    font-size: 16px;
    font-weight: 400;
    color: grey;
    margin-top: 15px;
}
.enviogratiscarrito{
    margin-top: 17px;
    color: rgb(3, 104, 37);
}
.botoneliminarcarrito{
    cursor: pointer;
    text-align: left;
    background: white;
    width: 50px;
    border: none;
    margin-top: 20px;
    color: rgb(14, 105, 133);
}
@media screen and (max-width:852px){
.fondocarrito{
    padding-top: 0;
    background: white;
}
.preciocarrito{
    font-size: 18px;
    margin-right: 15px;
    margin: auto 20px ;
}
    .conteinerproductoseleccionado{
        width: 100%;
        margin: 0px auto ;
        padding: 0px;
        padding-bottom: 24px;
        margin-bottom: 0px;
        box-shadow: 0px 0px 0px 0px;
        }
        .productocarrito{
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 110px;
            margin-top: 23px;
            margin-bottom: 23px;
        }
        
.mostrartextotitulocarrito{
    font-size: 15px;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.cbasica{
    font-size: 13px;
    font-weight: 400;
    color: grey;
    margin-top: 10px;
}
.enviogratiscarrito{
    font-size: 13px;
    margin-top: 10px;
    color: rgb(3, 104, 37);
}
.botoneliminarcarrito{
    margin-top: 10px;
  
}

.botoncarrito{
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}
.img-producto{
    margin-right: 10px;
    margin-left: 10px;
}
.flexro{
    justify-content: space-around;
}
  }
  


  @media screen and (max-width:550px){
      .comprarbotoncarrito{
        font-size: 13px;
      }
    .infoaprobado{
        font-size: 10px;
        
    }
    .botoncarrito{
        font-size: 14px;
    }
    .cbasica{
        margin-top: 3px;
    }
    .preciocarrito{
        font-size: 18px;
        margin-right: 15px;
        margin: auto 20px ;
    }
    .img-producto{
        width: 30%;
        margin-right: 10px;
        margin-left: 10px;
    }
    .botoneliminarcarrito{
        margin-top: 3px;
    }
      }

@media screen and (max-width:350px){
    .img-producto{
        width: 40%;
        margin-right: 10px;
        margin-left: 10px;
    }
    .preciocarrito{
        font-size: 15px;
        margin-right: 15px;
        margin: auto 20px ;
    }
.mostrartextotitulocarrito{
    font-size: 12px;
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.cbasica{
    font-size: 10px;
    font-weight: 400;
    color: grey;
    margin-top: 10px;
}
.enviogratiscarrito{
    font-size: 10px;
    margin-top: 10px;
    color: rgb(3, 104, 37);
}
.botoneliminarcarrito{
    margin-top: 10px;
  
}
  }
  @media screen and (max-width:300px){
    .botoncarrito{
        font-size: 10px;
    }
}
  